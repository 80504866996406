<template>
  <div class="regBox">
    <!-- logo图片 -->
    <!-- <img src="../../assets/img/credis-logo.png"
         alt=""> -->
    <div class="fromBox">
      <!-- 标题 -->
      <h1>Register</h1>
      <p>Create your buyer account</p>
      <!-- 注册表单 -->
      <el-form ref="registerList"
               :model="registerList"
               :rules="resRules">
        <!-- 国家 -->
        <el-form-item prop="site"
                      class="siteItem">
          <el-select v-model="registerList.site"
                     placeholder="Please select country">
            <el-option v-for="item in siteList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 邮箱 -->
        <el-form-item prop="registerEmail"
                      class="regCode">
          <!-- 输入邮箱 -->
          <el-input placeholder="registerEmail"
                    v-model="registerList.registerEmail"></el-input>
          <!-- 获取验证码 -->
          <el-button class="code"
                     @click="userCode()"
                     :disabled="isSend">{{sendCode}}</el-button>
        </el-form-item>
        <!-- 输入验证码 -->
        <el-form-item prop="registerCode">
          <el-input placeholder="Code"
                    v-model="registerList.registerCode"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="registerPass">
          <el-input type="password"
                    placeholder="passWord"
                    autocomplete="new-password"
                    v-model="registerList.registerPass"></el-input>
        </el-form-item>
        <!-- 确认密码 -->
        <el-form-item prop="registerCheckpass">
          <el-input type="password"
                    placeholder="CheckpassWord"
                    autocomplete="new-password"
                    v-model="registerList.registerCheckpass"></el-input>
        </el-form-item>
        <!-- 名 -->
        <el-form-item prop="firstname">
          <el-input placeholder="firstname"
                    v-model="registerList.firstname"></el-input>
        </el-form-item>
        <!-- 姓 -->
        <el-form-item prop="lastname">
          <el-input placeholder="lastname"
                    v-model="registerList.lastname"></el-input>
        </el-form-item>
        <!-- 亚马逊个人地址 -->
        <el-form-item prop="amazonProfileUrl">
          <el-input placeholder="amazonProfileUrl"
                    v-model="registerList.amazonProfileUrl"></el-input>
        </el-form-item>
        <!-- 贝宝用户 -->
        <el-form-item prop="paypalAccount">
          <el-input placeholder="paypalAccount"
                    v-model="registerList.paypalAccount"></el-input>
        </el-form-item>
        <!-- 社交网页 -->
        <el-form-item prop="socialPage">
          <el-input placeholder="socialPage"
                    v-model="registerList.socialPage"></el-input>
        </el-form-item>
        <!-- 介绍 -->
        <el-form-item prop="presentation">
          <el-input placeholder="Personal profile"
                    v-model="registerList.presentation"></el-input>
        </el-form-item>
        <!-- 协议-->
        <el-form-item prop="checked">
          <el-checkbox v-model="registerList.checked" class="agreement">
            I accept the agreement
            <el-link :underline="false" type="primary" @click="$router.push('/F_terms')" style="vertical-align: top;">Terms | </el-link>&nbsp;&nbsp;
                         <el-link :underline="false" type="primary" @click="$router.push('/F_privacy')" style="vertical-align: top;">Privacy</el-link>
            </el-checkbox>
        </el-form-item>
      </el-form>
      <!-- 注册按钮 -->
      <el-button class="sign"
                 @click="userReg('registerList')" :loading="$store.state.loadingBool">Sign Up</el-button>
      <!-- 已有账号，登录 -->
      <p class="reg">Not a member?<el-link :underline="false"
                 type="primary"
                 @click="goLogin()">Log In</el-link>
      </p>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  userRegister, // 用户注册验证邮箱
  userRegisterform // 用户注册表单
} from '../../api/buyerFront'
export default {
  data () {
    // 注册邮箱验证
    var email = (rule, value, callback) => {
      // 邮箱正则
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      // 邮箱value判断
      if (!value) {
        callback(new Error('Mailbox cannot be empty'))
      } else if (!reg.test(value)) {
        callback(new Error('Please input correct email format'))
      } else {
        callback()
      }
    }
    // 注册密码验证
    var validatePass = (rule, value, callback) => {
      // 密码验证
      const passReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
      // 密码value验证
      if (value === '') {
        callback(new Error('Please input a password'))
      } else if (!passReg.test(value)) {
        callback(new Error('The password should contain at least numbers and English, and the length should be 6-20'))
      } else {
        if (this.registerList.registerCheckpass !== '') {
          this.$refs.registerList.validateField('registerCheckpass')
        }
        callback()
      }
    }
    // 注册界面确认密码
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please enter the password again'))
      } else if (value !== this.registerList.registerPass) {
        callback(new Error('The two passwords are inconsistent!'))
      } else {
        callback()
      }
    }
    // 注册界面复选框
    var check = (rule, value, callback) => {
      // (!value, value === undefined)
      if (!value) {
        callback(new Error('Please check'))
      } else {
        return callback()
      }
    }
    return {
      // 注册表
      registerList: {
        site: '', // 国家
        registerEmail: '', // 邮箱
        registerCode: '', // 邮箱验证码
        registerPass: '', // 密码
        registerCheckpass: '', // 确认密码
        firstname: '', // 名字
        lastname: '', // 姓
        amazonProfileUrl: '', // 亚马逊资料
        paypalAccount: '', // 贝宝账户
        socialPage: '', // 社交网页
        presentation: '', // 介绍
        role: 'RULE_BUYER', // 注册角色
        checked: false
      },
      // 国家列表
      siteList: [
        // 美国
        {
          value: 'US',
          label: 'US'
        },
        // 加拿大
        {
          value: 'CA',
          label: 'CA'
        },
        // 墨西哥
        {
          value: 'MX',
          label: 'MX'
        },
        // 英国
        {
          value: 'UK',
          label: 'UK'
        },
        // 德国
        {
          value: 'DE',
          label: 'DE'
        },
        // 法国
        {
          value: 'FR',
          label: 'FR'
        },
        // 意大利
        {
          value: 'IT',
          label: 'IT'
        },
        // 西班牙
        {
          value: 'ES',
          label: 'ES'
        },
        // 日本
        {
          value: 'JP',
          label: 'JP'
        },
        // 荷兰s
        {
          value: 'NL',
          label: 'NL'
        }
      ],
      // 获取验证码
      sendCode: 'Send Code',
      isSend: false,
      // 注册表单验证规则
      resRules: {
        // 国家
        site: [
          {
            required: true,
            message: 'Please select country',
            trigger: 'blur'
          }
        ],
        // 邮箱
        registerEmail: [
          { validator: email, trigger: 'blur' }
        ],
        // 验证码
        registerCode: [
          {
            required: true,
            message: 'Please enter the verification code',
            trigger: 'blur'
          }
        ],
        // 密码
        registerPass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        // 确认密码
        registerCheckpass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        // 名
        firstname: [
          {
            required: true,
            message: 'Please enter your first name',
            trigger: 'blur'
          }
        ],
        // 姓
        lastname: [
          {
            required: true,
            message: 'Please enter your last name',
            trigger: 'blur'
          }
        ],
        // 亚马逊地址
        amazonProfileUrl: [
          {
            required: true,
            message: 'Please input your Amazon information',
            trigger: 'blur'
          }
        ],
        // 贝宝账号
        // paypalAccount: [
        //   {
        //     required: true,
        //     message: 'Please enter your PayPal account',
        //     trigger: 'blur'
        //   }
        // ],
        // // 社交网页
        // socialPage: [
        //   {
        //     required: true,
        //     message: 'Please enter your social_page',
        //     trigger: 'blur'
        //   }
        // ],
        // // 介绍
        // presentation: [
        //   {
        //     required: true,
        //     message: 'introduce oneself to',
        //     trigger: 'blur'
        //   }
        // ],
        // 勾选协议
        checked: [
          { validator: check, trigger: 'change' }
        ]
      },
      loadingBool: false
    }
  },
  created () {
    this.$store.commit('getLoading', false)
    const that = this
    // 当前页面监视键盘输入
    document.onkeydown = function (e) {
      const e1 =
        e || event || window.event
      if (e1.keyCode === 13) {
        that.userReg('registerList')
      }
    }
  },
  methods: {
    // 用户注册第一步邮箱验证
    userCode () {
      // 邮箱正则
      var regTest = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
      // 判断邮箱是否为空
      if (this.registerList.registerEmail === '') {
        this.$refs.registerList.validateField('registerEmail', (errMsg) => { })
      } else if (regTest.test(this.registerList.registerEmail)) {
        // 调用用户注册验证邮箱接口
        userRegister({
          mail: this.registerList.registerEmail, // 邮箱
          role: this.registerList.role // 用户角色
        }).then(result => {
          // // ('result ==>', result)
          // 接口调用成功 请求吗为200
          if (result.data.code === 200) {
            var num = 60 // 定义验证时间
            this.isSend = true // 判断验证按钮
            // 验证码点击倒计时
            var timer = setInterval(() => {
              if (num === 0) {
                clearInterval(timer) // 清除定时器
                this.isSend = false // 判断验证按钮
                this.sendCode = 'Send Code'
              } else {
                this.sendCode = `${num}s recapture` // 定时器倒计时
                num--
              }
            }, 1000)
            this.$message.success(result.data.message) // 成功弹框提示
          } else if (result.data.code === 201) {
            this.$message.error(result.data.message) // 失败弹框提示
          }
        }).catch(err => {
          return err
        })
      }
    },
    // 用户注册
    userReg (formName) {
      const that = this
      // element自带的表单验证
      this.$refs[formName].validate((valid) => {
        // 判断valid
        if (valid) {
          // 验证通过
          // ('succ')
          // 调用用户注册接口
          this.$store.commit('getLoading', true)
          that.$store.state.loadingBool = true
          userRegisterform({
            code: this.registerList.registerCode, // 验证码
            data: {
              country: this.registerList.site, // 国家
              mail: this.registerList.registerEmail, // 邮箱
              amazon_profile_url: this.registerList.amazonProfileUrl, // 亚马逊地址
              firstname: this.registerList.firstname, // 名
              lastname: this.registerList.lastname, // 姓
              password: this.registerList.registerPass, // 密码
              paypal_account: this.registerList.paypalAccount, // 贝宝
              social_page: this.registerList.socialPage, // 社交网站
              presentation: this.registerList.presentation, // 个人介绍
              role: this.registerList.role // 用户身份
            }
          }).then(result => {
            that.$store.state.loadingBool = false
            // (result)
            // 接口调用成功 状态码为200
            if (result.data.code === 200) {
              this.$store.commit('getLoading', false)
              this.$message.success(result.data.message) // 成功弹框
              this.$router.push({ path: '/F_login' })// 注册跳转登录页
            } else if (result.data.code === 201) {
              this.$store.commit('getLoading', false)
              this.$message.error(result.data.message) // 是吧弹框
            } else {
              this.$store.commit('getLoading', false)
            }
          }).catch(err => {
            return err
          })
        } else {
          // ('err')
        }
      })
    },
    goLogin () {
      this.$router.push({ path: '/F_login' })
    }
  }
}
</script>

<style lang="less" scoped>
// .el-loading-mask {
//   height: 100%;
// }
// .register {
//   position: relative;
//   padding-bottom: 100px;
// height: 100%;
.regBox {
  margin: 100px 0;
  padding-bottom: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 420px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  img {
    height: 40px;
    width: auto;
  }
  .fromBox {
    width: 100%;
    border: 1px solid #eeeeee;
    h1 {
      background-color: #f8ac59;
      font-size: 24px;
      font-weight: normal;
      line-height: 30px;
      color: white;
      padding: 15px 0;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      color: #6c757d;
      text-align: left;
      padding-left: 25px;
      margin-top: 25px;
    }
    .el-form {
      padding: 0 24px;
      .siteItem {
        margin-bottom: 0;
        margin-top: 25px;
        .el-form-item__content {
          width: 100%;
          .el-select {
            width: 100%;
          }
        }
      }
      .el-form-item {
        &:last-child {
          // margin: 0;
          display: flex;
          justify-content: flex-start;
        }
      }
      .regCode {
        /deep/.el-form-item__content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 25px 0 0 0;
          .code {
            margin-left: 10px;
          }
        }
      }
    }
    .sign {
      width: calc(100% - 50px);
      margin: 0 25px;
      background-color: #f8ac59;
      border-color: #f8ac59;
      color: white;
    }
    .reg {
      margin-top: 0;
      line-height: 30px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}
// }
</style>
<style scoped>
.pText {
  float: right;
  margin-top: 0 !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  font-size: 12px !important;
  color: #409EFF !important;
}
</style>
